<template>
  <div class="page-wrapper white">
  <div class="page-info psychology-page">
      <el-row class="page-row w150">
        <el-col class="item" v-for="(item, index) in list" :key="index">
          <div class="inner">
            <div class="label">{{ item.label }}</div>
            <div class="content" v-if="item.value">{{ item.value }}</div>
            <div class="content" v-else>
              <el-tag class="mr-1 mb-1" v-for="(child, cid) in item.list" :key="cid">{{ child }}</el-tag>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page-tool border">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import { as_major_show, as_subject_show } from "@/api";
export default {
  _config:{
    route:{
      path:"detail",
      meta:{"title":"查看"},

    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const { query: { id }, path } = this.$route
      let api = ''
      if(path.includes('major')) {
        api = as_major_show
      } else {
        api = as_subject_show
      }
      if(!id || !api) return

      const loading = this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      api({ id }).then(res => {
        const { school, direction, major, grade, subjects_arr } = res
        const list = grade.map(i => i.name)
        const info = [
          { label: '应用校区', value: school },
          { label: '应用年级', list },
          { label: '学习方向', value: direction },
          { label: '专业方向', list: major }
        ]
        if(path.includes('subject')) {
          info.push({ label: '选科组合', list: subjects_arr },)
        }
        this.list = info
      }).finally(() => {
        loading.close();
      })

    }
  }
}
</script>

<style lang="scss" scoped></style>
